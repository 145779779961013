<template>

  <div class="photo-element">
    <img :src="bgCamera" alt="bgCamera" class="bg-image"/>
    <div class="photo-wrap">
      <a :href="'/photos/' +  photo.id + '/edit'" v-if="currentUserId === photo.user.id || currentUserRole <= 2">
        <div class="edit-button">
          <i class="fas fa-edit"></i>
        </div>
      </a>
      <a :href="'/contents/' + photo.content.id" v-if="photo.content">
        <div class="ph-exists-content">
          <i class="fad fa-award"></i>
        </div>
      </a>
      <div class="photo">
        <a :href="'/photos/' + photo.id">
          <img :src="photo.public.url" :alt="photo.id"/>
        </a>
      </div>
    </div>
    <div class="body">
      <div class="title">{{ photo.title }}</div>
      <div class="avatar">
        <a :href="'/photos/user/' + photo.user.id">
          <img :src="photo.user.url" :alt="photo.user.nickname"/>
        </a>
        <span>
          <a :href="'/photos/user/' + photo.user.id">{{ photo.user.nickname}}</a>
        </span>
      </div>

      <div class="content">
        <div class="content-title">
          <div v-if="photo.content">
            <a :href="`/contents/${photo.content.id}`"><span class="title">{{photo.content.title}}</span></a><span> > </span><a :href="`/contents?category_1=${photo.content.categories[0].title}`"><span class="category">{{photo.content.categories[0].title}}</span></a>
          </div>
          <div v-else>
            <div class="date"><i class="far fa-calendar-alt"></i> {{date}}</div>
          </div>
        </div>
        <div class="caption">
          <p>{{ photo.caption }}</p>
        </div>
      </div>

      <div class="tags">
        <tag v-if="photo.tags.length === 0" style="visibility: hidden"><span class="tag"></span></tag>
        <tag v-for="tag in photo.tags" :name="tag" :key="tag" :href="`/photos?keyword=${tag}`"/>
      </div>
    </div>
  </div>
</template>

<script>
import tag from '../common/tag'
import bgCamera from '../../../img/bg-camera.png'
import dateUtil from "../../../js/date-util";

export default {
  name: "photo-element",
  props: ['photo','currentUserId', 'currentUserRole'],
  components: { tag },
  data: ()=> {
    return {
      bgCamera: bgCamera
    }
  },
  computed: {
    date() {
      return dateUtil.formatDate(new Date(this.photo.createdAt),'YYYY.MM.DD hh:mm')

    }
  }
}
</script>

<style scoped>

</style>