<template>
  <div class="instagram-list" id="instagram" ref="instagram">
    <h1><img :src="instagramLogo" class="img-instagram"/></h1>
    <h3 class="tag-name">#{{tag}}</h3>
    <div v-if="photos.length > 0" >
      <div class="tag-name">#{{tag}}</div>
      <div class="row">
        <div class="col-sm-4 col-12" v-for="photo in photos">
          <div class="photo-wrapper">
            <a :href="photo.permalink"><img :src="photo.mediaUrl" alt="photo" class="img-thumbnail"/></a>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="col-sm-12 spinner">
        <div class="fa-3x">
          <i class="fas fa-spinner fa-spin"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../../js/axios-extend";
import instagramLogo from '../../../img/Instagram_logo.png'


export default {
  name: "instagram-list",
  props: ['tag'],
  mounted() {
    this.getData()
    if(location.hash && location.hash.indexOf('#instagram') >= 0) {
      this.$refs.instagram.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start"
      });
    }
  },
  data: ()=> {
    return {
      photos: [],
      instagramLogo: instagramLogo
    }
  },
  methods: {
    getData() {
      axios.get(`/photos/instagram/${this.tag}`).then( (response) => {
        if(response.data.status === 200) {
          this.photos = response.data.photos
        }
      });
    }
  }
}
</script>

<style scoped>

</style>