<template>
  <div>
    <div v-if="loading">
      <div class="photo-container" ref="container" v-on:scroll="scrollContainer">
        <photo-element v-for="photo in photos" :photo="photo" :key="photo.id" :current-user-id="currentUserId" :current-user-role="currentUserRole"></photo-element>
      </div>
      <div class="dot-container">
        <span :class="'dot ' + (index === currentIndex ? 'active' : '') " v-for="(photo,index) in photos" v-on:click="showElement(index)"></span>
      </div>
      <div v-if="instagramTag">
        <instagram-list :tag="instagramTag"></instagram-list>
      </div>
    </div>
    <div v-else>
      <div class="col-sm-12 spinner">
        <div class="fa-3x">
          <i class="fas fa-spinner fa-spin"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import photoElement from "./photo-element"
import instagramList from "./instagram-list";
import axios from "../../../js/axios-extend";

export default {
  name: "photo-list",
  components: { photoElement, instagramList },
  props: [ 'searchParams', 'page', 'limit', 'userId', 'currentUserId', 'currentUserRole', 'instagramTag'],
  mounted() {
    if (this.searchParams) {
      this.getData();
    }
    this.currentPage = this.page
  },
  data: () => {
    return {
      photos: [],
      currentIndex: 0,
      currentPage: 0,
      loading: false
    }
  },
  methods: {
    getData(option = {}) {
      const params = this.searchParams
      if (this.limit > 0) {
        params.limit = this.limit;
      }
      if (this.userId) {
        params.userId = this.userId
      }
      params.page =  option.page || this.currentPage
      axios.get('/photos.json', { params: params } ).then(response => {
        if( response.data.status === 200 ) {
          this.photos = response.data.photos
          this.$emit('pageChange', response.data.page, response.data.pageCount)
        }
        this.loading = true
      });
    },

    showElement(index) {
      this.currentIndex = index
      let x = 0;
      if(index === this.photos.length - 1) {
        x = this.$refs.container.scrollWidth - this.$refs.container.offsetWidth
      }
      else {
        x = this.scrollXList()[index]
      }
      this.$refs.container.scrollTo({
        top: 0,
        left: x,
        behavior: 'smooth'
      });
    },
    elementWidth() {
      const child  = this.$refs.container.children[0]
      return child.offsetWidth
    },

    scrollXList() {
      const list = []
      for (let i = 0 ; i < this.photos.length; i ++ ) {
        list.push( ((this.elementWidth() + 30 )  * i ) - (this.$refs.container.offsetWidth / this.photos.length ) * i)
      }
      return list
    },

    scrollContainer(event) {
      const position = event.currentTarget.scrollLeft
      const list = this.scrollXList()
      let index = 0
      for( ; index < list.length; index ++ ) {
        if (position < list[index]) {
          break
        }
      }
      if(index === 0) {
        this.currentIndex = 0
      }
      else {
        this.currentIndex = index - 1
      }
    }
  }
}
</script>

<style scoped>

</style>